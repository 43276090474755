.alert-box{
  width: 100%;
  @include sans-serif;
  border-style: solid;
  border-width: 1px;
  @include display-flex(row, center, space-between);
  transition: opacity 0.3s ease-out;
  background-color: #f04124;
  border-color: #de2d0f;
  .alert-message{
    color: white;
    padding: 0.2rem 1rem;
  }
  .alert-close{
    font-size: 2rem;
    margin-inline-start: auto;
    padding: 0 0.5rem;
    color: #333333;
    opacity: 0.3;
    &:hover, &:focus{
      opacity: 0.5;
    }
  }
  a:not(.alert-close){
    @include text-hover(white, #ccc);
    text-decoration: underline;
  }
}
