a{
  line-height: inherit;
  text-decoration: none;
  @include text-hover($primary-color, darken($primary-color, 5%));
  word-break: break-word;
  &:hover{
    cursor: pointer;
  }
}

.classic-link{
  text-decoration: underline;
  @include text-hover(rgb(0, 0, 238), rgb(49, 156, 194));
}

.link{
  @include link-underline-on-hover($dark-grey, $link-hover-grey);
}
.link-white{
  @include link-underline-on-hover(white, white);
}

.content-link{
  @include link-underline-on-hover($grey, lighten($grey, 5%));
}
