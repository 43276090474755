$global-radius: 2px;

@mixin radius($radius:$global-radius){
  border-radius: $radius;
}
@mixin radius-top($radius:$global-radius){
  border-start-start-radius: $radius;
  border-start-end-radius: $radius;
}
@mixin radius-bottom($radius:$global-radius){
  border-end-start-radius: $radius;
  border-end-end-radius: $radius;
}
@mixin radius-left($radius:$global-radius){
  border-start-start-radius: $radius;
  border-end-start-radius: $radius;
}
@mixin radius-right($radius:$global-radius){
  border-start-end-radius: $radius;
  border-end-end-radius: $radius;
}

@mixin radius-diagonal-a($radius:$global-radius){
  border-start-start-radius: $radius;
  border-end-end-radius: $radius;
}

@mixin radius-diagonal-b($radius:$global-radius){
  border-end-start-radius: $radius;
  border-start-end-radius: $radius;
}

@mixin radius-horizontal-group($radius:$global-radius){
  :first-child{
    @include radius-left($radius);
  }
  :last-child{
    @include radius-right($radius);
  }
}

@mixin radius-vertical-group($radius:$global-radius){
  :first-child{
    @include radius-top($radius);
  }
  :last-child{
    @include radius-bottom($radius);
  }
}

@mixin radius-horizontal-group-bottom($radius:$global-radius){
  :first-child{
    border-end-start-radius: $radius;
  }
  :last-child{
    border-end-end-radius: $radius;
  }
}
