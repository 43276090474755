.dropdown-wrapper{
  position: relative;
  display: flex;
  align-items: stretch;
  [role="menu"]{
    text-align: start;
    display: none;
    position: absolute;
    z-index: 30;
    min-width: 10em;
    inset-block-start: 100%;
    &:not(.dropdown-align-end){
      inset-inline-start: 0;
    }
    &.dropdown-align-end{
      inset-inline-end: 0;
    }
    white-space: nowrap;
    background-color: $off-white;
    color: $dark-grey;
    &, a, button, span:not(.counter), .fa{
      text-align: start !important;
      color: $dark-grey;
    }
    @include shy-border;
    li{
      width: 100%;
      a, button{
        display: block;
        width: 100%;
        font-weight: normal;
      }
    }
    .selected{
      background-color: #666;
      &, .fa{
        color: white !important;
      }
    }
    a, button{
      padding: 0.5em;
      @include bg-hover(white);
    }
  }
}


.dropdown-label{
  &, &:hover{
    color: #444;
    cursor: default;
  }
  &::first-letter{
    text-transform: uppercase;
  }
}
