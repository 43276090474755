label, legend{
  color: $label-grey;
  display: block;
  font-weight: normal;
  margin-block-end: 0;
}

label{
  font-size: 0.9rem;
  line-height: 1.5rem;
}

legend{
  .title{
    font-size: 1rem;
  }
  .description{
    font-size: 0.9rem;
  }
}
