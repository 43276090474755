.group, .groupProfile, .group-board-header{
  .actions{
    @include display-flex(row);
    font-weight: normal;
    text-align: center;
    a{
      margin-inline-end: 0.5em;
    }
    .accept{
      @include tiny-button($success-color);
    }
    .joinRequest, .joinGroup{
      @include tiny-button($light-blue);
    }
    .cancelRequest, .decline{
      @include tiny-button($grey);
    }
    .requested{
      color: $dark-grey;
    }
  }
  .restrictions{
    text-align: center;
    @include radius;
    @include tiny-button-padding;
    background-color: grey;
    color: white;
    margin: 0 0 0.5em 0;
  }
  /*Small screens*/
  @media screen and (width < $small-screen) {
    .actions{
      a{
        margin: 0;
      }
    }
    .restrictions{
      margin: 0.5em;
    }
  }
}

.groupBoard{
  .restrictions{
    color: white;
  }
}
