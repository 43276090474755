$modalWidth: 38em;
$mediumModalWidth: 46em;
$largeModalWidth: 60em;

#modalWrapper, #overlay{
  background: rgba(black, 0.55);
  overflow: auto;
  @include position(fixed, 0, 0, 0, 0);
  &.hidden{
    inset-block-start: -100%;
    inset-block-end: 100%;
  }
}

// Add this class to the body to make the modalWrapper scroll the only scroll
// while the modal is opened
body.openedModal{
  overflow: hidden;
}

#modal{
  position: relative;
  margin: 0 auto;
  max-width: $modalWidth;
  &.modal-medium{ max-width: $mediumModalWidth; }
  &.modal-large{ max-width: $largeModalWidth; }
  .close{
    padding: 0;
    line-height: 0;
    font-size: 3.6rem;
    font-weight: bold;
    color: white;
    @include shy(0.9);
    @include position(absolute, 2.6rem, 0);
    &:focus {
      color: $light-grey
    }
  }
  /*Small screens*/
  @media screen and (width < $small-screen) {
    padding: 2em 0.2em;
    min-width: 80vw;
    margin-block-start: 10px;
    .close{
      inset-block-start: 10px;
      inset-inline-end: 10px;
    }
  }
  /*Medium / Large screens*/
  @media screen and (width >= $smaller-screen) {
    // Important for the place of the .close button
    padding: 2em;
  }
  /*Large screens*/
  @media screen and (width >= $small-screen) {
    .modal-large{
      min-width: 60em;
    }
  }
}

#modalContent{
  @include radius;
  background-color: white;
  &.dark{
    background-color: $topbar-bg-color;
  }
  box-shadow: 3px 3px 10px 3px rgba(#222, 0.5);
  /*Small screens*/
  @media screen and (width < $small-screen) {
    padding: 1em 0;
  }
  /*Large screens*/
  @media screen and (width >= $smaller-screen) {
    padding: 1em;
  }
}
