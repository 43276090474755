.unread-flag{
  @include display-flex(row, center, center);
  margin-inline-start: 0.3em;
  font-size: 0.8em;
  opacity: 0.8;
  align-self: center;
  flex: 0 0 auto;
  padding: 0.2em;
  .fa{
    color: $light-blue;
  }
}

.notification{
  &.unread{
    background-color: $unread-color;
  }
  &:not(.unread){
    @include bg-hover(white);
    .unread-flag{
      display: none;
    }
  }
}

.notification{
  img{
    max-height: 3em;
    max-width: 3em;
    @include radius;
  }
}

.showNotificationsSettings{
  margin-block-start: 1em;
  .fa{
    margin-inline-end: 0.5em;
  }
}
