$default-button-margin: 0.1em;
$default-button-padding: 1em;

@mixin custom-button($bg-color, $color: white, $padding:$default-button-padding, $darken:15%){
  @include text-hover($color);
  padding: $padding;
  // include last to keep the transition effect
  @include bg-hover($bg-color, $darken);
}

@mixin tiny-button-padding(){
  padding: 0.2em 0.6em;
}

// A mixin for selectors already affected by .tiny-button
// but just requiring some color customization
@mixin tiny-button-color($bg-color, $color:null, $darken:15%){
  @include bg-hover($bg-color, $darken);
  @if $color {
    @include text-hover($color);
  }
  // Override text-hover transition:color, which was overriding bg-hover transition:background-color
  transition-property: color, background-color;
}

$tiny-button-line-height: 1.6rem;
@mixin tiny-button($bg-color, $color: white, $darken:15%, $radius: $global-radius){
  @include tiny-button-padding;
  border-radius: $radius;
  @include tiny-button-color($bg-color, $color);
  // Align <button class="tiny-button"> line-height on <a> default
  line-height: $tiny-button-line-height;
}

@mixin dangerous-button(){
  @include dangerous-action;
  padding: $default-button-padding;
  margin: $default-button-margin;
}

@mixin button-group{
  :first-child{
    @include radius-left;
  }
  :not(:first-child){
    @include radius-left(0);
  }
  :not(:last-child){
    @include radius-right(0);
  }
  :last-child{
    @include radius-right;
  }
}

@mixin big-button($color){
  @include custom-button($color);
  @include radius;
  font-weight: bold;
  &:disabled{
    opacity: 0.8;
  }
}

@mixin shy-button-label(){
  span{
    display: none;
    @include transition(display);
  }
  &:hover, &:focus{
    span{
      display: inline;
    }
  }
}
