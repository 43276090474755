// See https://web.archive.org/web/20180409082055/https://software.intel.com/en-us/html5/hub/blogs/ellipse-my-text/
.clamped{
  // margin: 30px 0;
  line-height: 1.6;
  overflow: hidden;
  position: relative;
  max-height: 4.8em; /*display ellipsis after 3 lines*/
}

.clamped:before  {
  background: inherit;
  @include position(absolute, null, 0, 0);
  content: '\2026';
}

// .clamped:after{
//   content: '';
//   background: inherit;
//   position: absolute;
//   height: 50px;
//   width: 100%;
//   z-index: 1;
// }