$input-border-color: #c4c4c4;
$input-font-color: black;
$input-height: 2.3rem;

input[type="text"], input[type="password"], input[type="email"], input[type="search"], input[type="url"], textarea{
  @include radius;
  background-color: white;
  font-family: inherit;
  font-size: 1rem;
  color: $input-font-color;
  display: block;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  height: $input-height;
  width: 100%;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  &:disabled{
    background-color: #ddd;
    cursor: default;
  }
  &.has-alertbox{
    margin-block-end: 0;
  }
}

label input[type="checkbox"]{
  display: inline;
  height: auto;
  margin-inline-end: 0.4em;
}

textarea{
  line-height: 1.6rem;
}

input[type="text"], input[type="search"], input[type="password"], input[type="email"], textarea{
  border: 1px solid $input-border-color;
  &:focus{
    border-color: darken($input-border-color, 10%);
  }
}

input[type="checkbox"]{
  cursor: pointer;
  margin: 0;
  width: auto;
}

input[type="checkbox"] + label{
  padding: 0.2em 0.8em;
}

input[type="file"]{
  width: 100%;
  height: auto;
  margin: 0;
}

input[type="password"]{
  // forcing standard password dots
  font-family: sans-serif !important;
}

input[type="color"]{
  height: 3em;
  width: 3em;
  cursor: pointer;
}

input:disabled{
  cursor: not-allowed;
  opacity: 0.5;
}

textarea{
  height: auto;
  min-height: 50px;
  // don't set a min-width as textarea are sometimes in a flex row
  // with other elements (ex: transactions new_message)
  // min-width: 100%;
  max-width: 100%;
  resize: none;
  border: solid 1px #ccc;
  &:focus{
    box-shadow: 0 0 2px $glow;
  }
}

label{
  cursor: pointer;
}

.inputGroup, .input-group{
  width: 100%;
  @include display-flex(row, left, flex-start);
  margin-block-end: 0.5em;
  button.postfix{
    height: 2.2rem;
    white-space: nowrap;
    @include capitalized;
  }

  /*Very small screens*/
  @media screen and (width < $very-small-screen) {
    flex-direction: column;
    button{
      width: 100%;
    }
  }

  /*Large screens*/
  @media screen and (width >= $very-small-screen) {
    button.postfix{
      @include radius-right;
      @include radius-left(0);
      width: auto;
      padding: 0 1em;
    }
  }
}

/* Attach elements to the beginning or end of an input */
.postfix{
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-block-start: 0;
  padding-block-end: 0;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  font-size: 0.9rem;
  height: $input-height;
  line-height: $input-height;
  /* Adjust padding, alignment and radius if pre/post element is a button */
  &.button{
    padding: 0;
    text-align: center;
    line-height: 2.1rem;
    border: none;
  }
  /* Separate postfix styles when on span or label so buttons keep their own */
  &span, &label{
    background: #f2f2f2;
    border-left: none;
    color: $input-font-color;
    border-color: $input-border-color;
  }
}
