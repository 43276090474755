#flashMessage{
  background-color: $success-color;
  @include radius(3px);
  padding: 0.8em 1em;
  margin: 1em;
  color: white;
  @include display-flex(row, center, center);
  .close{
    color: white;
    margin-inline-start: auto;
    font-size: 2rem;
  }

  @include position(fixed, -10em, 0, null, null, 100);
  @include transition(top);
  &.active{
    background-color: $warning-color;
    inset-block-start: 0;
  }
  &:not(.active){
    display: none;
  }

  /*Very small screens*/
  @media screen and (width < $very-small-screen) {
    inset-inline-start: 0;
    &.active{
      inset-block-start: auto;
      inset-block-end: 0;
    }
  }
}
