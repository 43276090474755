/* Add height value for select elements to match text input height */
select{
  width: 100%;
  height: $input-height;
  appearance: none;
  background-color: #fafafa;
  background-position: 100% center;
  background-repeat: no-repeat;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: normal;
  color: $input-font-color;
  line-height: normal;
  border: 1px solid $input-border-color;
  border-radius: 0;
  // ForkAwesome is needed to correctly display icons
  font-family: 'ForkAwesome', $sans-serif;
  &:hover{
    background-color: #f3f3f3;
    border-color: #999;
  }
  &:disabled{
    background-color: #ddd;
    cursor: default;
  }
  option{
    // works in Firefox not Chrome
    font-size: 1rem;
    padding-block-start: 0.2rem;
    padding-block-end: 0.2rem;
  }
}
