@mixin common-flex($direction:null, $align:null, $justify:null, $wrap:null) {
  @if $direction {  flex-direction: $direction; }
  @if $align { align-items: $align; }
  @if $justify { justify-content: $justify; }
  @if $wrap { flex-wrap: $wrap; }
}

@mixin display-flex($direction:null, $align:null, $justify:null, $wrap:null) {
  display: flex;
  @include common-flex($direction, $align, $justify, $wrap);
}

@mixin display-inline-flex($direction:null, $align:null, $justify:null, $wrap:null) {
  display: inline-flex;
  @include common-flex($direction, $align, $justify, $wrap);
}
