// SCSS utils are all the variables and mixins
// that don't define rules but on which other files depend to build their rules

// Make sure the charset is set appropriately
@charset 'UTF-8';

// those can't be split into several non-partial files (despite the fact that
// it would allow better error reports than just 'base.scss failed'), as global
// variables need to be accessible to all
@import 'media_query_thresholds';
@import 'fonts_and_typo_utils';
@import 'mixins';
@import 'colors_utils';
@import 'links_utils';
@import 'radius_utils';
@import 'buttons_utils';
@import 'panel_utils';
@import 'shadow_box';
@import 'background';

$topbar-bg-color: $darker-grey;
$topbar-height: 46px;
$smallscreen-connection-buttons-height: 40px;
$user-box-heigth: 3em;
$coverTextBg: rgba($dark-grey, 0.9);
$search-section-width: 7em;
$users-home-nav-avatar-size: 5em;
$top-bar-fixed-threshold: 340px;