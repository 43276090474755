.uri{
  color: $grey;
  font-size: 0.8rem;
}

.property-value{
  color: $grey;
}

.wikisource{
  .icon{
    height: 1em;
    padding-inline-end: 0.35em;
  }
}
