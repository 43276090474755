.groupLi, .group-board-header{
  .group-cover-picture .name{
    text-align: center;
    font-size: 1.5em;
    @include text-wrap;
    max-width: 100%;
  }
}
.groupLi{
  .group-cover-picture .name{
    max-height: 3em;
  }
}

.group-board-header{
  .name{
    color: white;
    margin: auto;
    font-size: 1.6rem;
    font-weight: bold;
    @include serif;
    padding: 0.5em 1em;
    @include radius;
    color: white;
    background-color: rgba($dark-grey, 0.9);
  }
}
