.checkWrapper{
  @include display-flex(column);
  div:first-child{
    flex: 1 1 auto;
  }
  .check{
    width: 3em;
    align-self: center;
  }
}
