@mixin panel($bg-color:$contrast){
  @include shy-border;
  background-color: $bg-color;
  @include radius;
  padding: 0.8em 1em 0.8em 1em;
  /*Very small screens*/
  @media screen and (width < $very-small-screen) {
    margin: 0.2em 0 0.3em 0;
  }
  /*Medium to Large screens*/
  @media screen and (width >= $very-small-screen) {
    margin: 0.4em 0 0.6em 0;
  }
}
