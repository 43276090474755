.fa-times-circle{
  // avoid being styled by view specific .fa color
  color: $warning-color !important;
  font-size: 2rem;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0.2rem;
}

.back{
  .fa{
    font-size: 1rem;
  }
  &.button{
    background-color: grey;
  }
}

.img-zoom-in{
  cursor: zoom-in;
}
