$serif: 'Alegreya';
$sans-serif: 'Alegreya Sans';

$header-font-family: $serif, serif;
$body-font-family: $sans-serif, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$topbar-link-font-family: $header-font-family;
$tabs-navigation-font-family: $header-font-family;
$body-font-color: #222;

$button-font-family: $header-font-family;
$input-font-family: $sans-serif;
$label-font-family: $sans-serif;

@mixin serif() {
  font-family: $serif;
}
@mixin sans-serif() {
  font-family: $sans-serif;
}
