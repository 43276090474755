@import "#general/scss/utils";

.confirmationModal{
  text-align: center;
  // Mimicking .reveal-modal .close-reveal-modal
  #back{
    position: absolute;
    font-weight: bold;
    font-size: 1.3em;
    color: #aaa;
    inset-block-start: 1.4em;
    inset-inline-start: -0.1em;
  }
  .check{
    margin-block-start: 1em;
  }
  .fa-check-circle{
    font-size: 1.4rem;
    color: $success-color;
  }
  label{
    text-align: start;
    margin-block-start: 1em;
    font-size: 1.1em;
  }
  p{
    margin-block-end: 0.5em;
  }
  a.button{
    margin-block-start: 0.5em;
  }
}
