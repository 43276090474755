.panel{
  @include panel;
}
.dark-panel{
  @include panel($dark-grey);
  color: white;
  .indicator{
    color: white;
    &:hover{
      color: white;
    }
  }
}