#topBar{
  // Required to set a z-index
  position: relative;
  // Make the top bar appear above main
  z-index: 1;
}

main{
  position: relative;
  z-index: 0;
  /*Small screens*/
  @media screen and (width < $small-screen) {
    // Allow scrollTo$Element to really get the top of the div
    // at the top of the screen, which would not be possible
    // if main was smaller than the screen
    min-height: calc(100vh - $topbar-height);
    &.active-connection-button{
      margin-block-end: $smallscreen-connection-buttons-height;
    }
  }

  @media screen and (height >= $top-bar-fixed-threshold) {
    // Prevent children margin-top to be collapsed with that margin-top
    // by setting a border, so that children margin-top start from that border
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
    border-top: 1px solid transparent;
    margin-block-start: calc($topbar-height - 1px);
  }
}

#modalWrapper{
  z-index: 2;
}

body.hasOverlay{
  #topBar{
    z-index: 0;
  }
  // It would also be possible to disable body scroll to let the scroll monopoly to the overlay
  // but that would loose the scroll level when closing the overlay
  // overflow: hidden;
  // max-height: 100vh;
}
