.secondary{
  color: lighten($primary-color, 10%) !important;
}

.grey{
  @include color-class($grey);
}

.light-grey{
  @include color-class($light-grey);
}

.dark-grey{
  @include color-class($dark-grey);
}
.light-blue{
  @include color-class($light-blue);
}

.white{
  color: #fff;
}

.contrast{
  background-color: $contrast;
}
